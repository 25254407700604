<template>
  <div class="about">
    <Header />
    <h1>This is the Videos page</h1>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'

export default {
  components: {Header},
  setup() {
    
  },
}
</script>

